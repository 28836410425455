import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Meta from "vue-meta"
import shareIt from 'vue-share-it';
import VueGtag from "vue-gtag";

import { library } from "@fortawesome/fontawesome-svg-core"
import { faBars, faPhone, faDraftingCompass, faMobileAlt, faAngleDown, faTimes, faQuoteLeft, faQuoteRight, faChevronRight, faMapMarkerAlt, faAt, faFax, faExternalLinkAlt, faBookOpen, faScrewdriver, faHandsHelping, faGem, faLocationArrow, faClock, faComments } from "@fortawesome/free-solid-svg-icons"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"


import "luminous-lightbox/dist/luminous-basic.min.css"

library.add(faBars, faPhone, faFacebook, faInstagram, faDraftingCompass, faMobileAlt, faAngleDown, faTimes, faQuoteLeft, faQuoteRight, faChevronRight, faMapMarkerAlt, faAt, faFax, faExternalLinkAlt, faBookOpen, faScrewdriver, faHandsHelping, faGem, faLocationArrow, faClock, faComments)
Vue.component("font-awesome-icon", FontAwesomeIcon)

const requireComponent = require.context("./components/elements", false, /[\w-]+\.vue$/)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, "$1")
  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.config.productionTip = false
Vue.use(Meta)
Vue.use(shareIt);
Vue.use(VueGtag, {
  config: { id: "G-P6Q23JFS46",
  params: {
    send_page_view: true
  } }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
