<template>
  <a :href="url.replace('/http', 'http')" v-if="url.includes('http')" rel="noreferrer" target="_blank">
    <slot></slot>
  </a>
  <router-link :to="url" v-else>
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: "PageLink",
  props: ["to", "href"],
  computed: {
    url: function () {
      var val = ""
      if (this.to) val = this.to
      if (this.href) val = this.href
      if (val && val != "#" && val.indexOf("/") != 0) val = "/" + val
      return val
    }
  }
}
</script>
