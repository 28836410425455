import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: "/",
    //   name: "index",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/about",
    //   name: "about",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/choosing",
    //   name: "choosing",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/memorials",
    //   name: "memorials",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/inscriptions",
    //   name: "inscriptions",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/keepsakes",
    //   name: "keepsakes",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/examples",
    //   name: "examples",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/contact",
    //   name: "contact",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/products",
    //   name: "products",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/product",
    //   name: "product",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // }
    {
      path: "/",
      name: "root",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    {
      path: "/404-not-found",
      name: "404",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    {
      path: "/keepsakes/:url",
      name: "keepsake-page-by-url",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    {
      path: "/:url",
      name: "page-by-url",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    {
      path: "/:itemid/:name",
      name: "obit",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
