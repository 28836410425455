import Vue from "vue"
import Vuex from "vuex"
import api from "@/services/api"
import datetimeutil from "date-and-time"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    page: {},
    parents: [],

    site_id: "ctbmasons",
    settings: null,
    main_pages: null,
    privacy_page: null,
    contact_page: null,
    obits: null,
    latest_obits: null,

    homeID: "qv2e2kyYN",
    privacyID: "yT4M7dM7Q",
    contactID: "47RpqiTid",
    memorialsID: "T5D0GQg1n",
    keepsakesID: "Bhwj8K4Ya",
    inscriptionsID: "Ue5sWC6KJ",
    otherProductsID: "UuuoHMH9Q",
    renovationsID: "egxhq82Vy",
    exampleID: "dmyCBxNM5",
    obitID: "LFnzIkvc1"
  },
  getters: {
    isHomePage(state) {
      return state.page.ItemID && state.page.ItemID == state.homeID
    },
    isAboutPage(state) {
      return state.page.ItemID && state.page.ItemID == state.aboutID
    },
    isExamplePage(state) {
      return state.page.ItemID && state.page.ItemID == state.exampleID
    },
    isContactPage(state) {
      return state.page.ItemID && state.page.ItemID == state.contactID
    },
    isKeepsakesPage(state) {
      return state.page.ItemID && state.page.ItemID == state.keepsakesID
    },
    isRenovationsPage(state) {
      return state.page.ItemID && state.page.ItemID == state.renovationsID
    },
    isTopParent(state) {
      return (
        state.page.ItemID &&
        (state.page.ItemID == state.memorialsID ||
          state.page.ItemID == state.keepsakesID ||
          state.page.ItemID == state.otherProductsID)
      )
    },
    isAProduct(state) {
      return state.page.ItemType && state.page.ItemType == "page.products"
    },
    isACategory(state) {
      return state.page.ItemType && state.page.ItemType == "page.categories"
    },
    hasBreadcrumb(state) {
      return (
        (state.page.ItemID &&
          (state.page.ItemID == state.memorialsID ||
            state.page.ItemID == state.keepsakesID ||
            state.page.ItemID == state.otherProductsID)) ||
        (state.page.ItemType &&
          (state.page.ItemType == "page.categories" ||
            state.page.ItemType == "page.products" ||
            state.page.ItemType == "page.sub"))
      )
    },
    hasGallery(state) {
      return (
        (state.page.ItemID && (state.page.ItemID == state.exampleID || state.page.ItemID == state.inscriptionsID)) ||
        (state.page.ItemType && state.page.ItemType == "page.sub")
      )
    }
  },
  mutations: {
    setPage(state, page) {
      state.page = page
    },
    setObits(state, obits) {
      state.obits = obits
    },
    setLatestObits(state, latest_obits) {
      state.latest_obits = latest_obits
    },
    clearParents(state) {
      state.parents = []
    },
    addParent(state, page) {
      state.parents.unshift(page)
    },
    setSettings(state, settings) {
      state.settings = settings
    },
    setPrivacyPage(state, page) {
      state.privacy_page = page
    },
    setContactPage(state, page) {
      state.contact_page = page
    },
    setMainPages(state, main_pages) {
      state.main_pages = main_pages.filter((p) => p.ItemID != state.homeID && p.ItemID != state.privacyID)
    }
  },
  actions: {
    getParent({ commit, state }) {
      return new Promise((resolve) => {
        commit("clearParents")
        resolve()
        if (state.page.ParentID)
          api
            .getPageByItemID(state.page.ItemType == "page.sub" ? state.keepsakesID : state.page.ParentID)
            .then((parent) => {
              commit("addParent", parent)
              if (parent.ParentID)
                api.getPageByItemID(parent.ParentID).then((topparent) => {
                  commit("addParent", topparent)
                })
            })
      })
    },
    getSettings({ commit, state }) {
      return new Promise((resolve) => {
        if (!state.settings)
          api.getPageByItemID("SETTINGS").then((settings) => {
            commit("setSettings", settings)
            resolve()
          })
        else resolve()
      })
    },
    getObits({ commit, state }) {
      return new Promise(resolve => {
        if (!state.obits)
        api.getObituaries().then(obits => {
            obits.forEach(obit => {
              //console.log(obit.date_of_funeral)
              if (obit.date_of_funeral) obit.order = datetimeutil.format(datetimeutil.parse(obit.date_of_funeral, "DD/MM/YYYY"), "YYYY-MM-DD")
            })
            obits.sort((a, b) => (a.order < b.order ? 1 : b.order < a.order ? -1 : 0))
            commit("setLatestObits", JSON.parse(JSON.stringify(obits.slice(0, 3))))
            commit("setObits", obits)
            resolve()
          })
        else resolve()
      })
    },
    getMainPages({ commit, state }) {
      return new Promise((resolve) => {
        if (!state.main_pages)
          api.getPages("", "page.main").then((main_pages) => {
            commit("setMainPages", main_pages)
            resolve()

            var promises = []

            main_pages.forEach((page) => {
              if (page.ItemID == state.privacyID) commit("setPrivacyPage", page)
              if (page.ItemID == state.contactID) commit("setContactPage", page)
            })

            Promise.all(promises).then(() => {
              commit("setMainPages", main_pages)
            })
          })
        else resolve()
      })
    }
  }
})
